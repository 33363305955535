:root {
  --ispui-input__height: 50px;
  --ispui-input__width: 100%;
  --ispui-button__line-height: 48px;
  --ispui-button__min-width: 105px;
  --ispui-button__font: 18px;

  --ispui-dropdown-content-padding: 15px 0 15px 10px;
  --ispui-dropdown-min-width: 60px;

  --isp-text-main-color: #344a5e;
}

.ispui-password {
  width: 100%;
}

body {
  display: flex;

  min-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--isp-text-main-color);

  font-family: "Ubuntu", sans-serif;
}

@import "../../node_modules/@ispui/ubuntu-fonts/fonts.css";
@import "./page/page.scss";
