.page__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  width: 500px;
  height: 100%;
  margin: 0 auto;
}
