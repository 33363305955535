.form__input-field_indent {
  position: relative;

  margin-bottom: 30px;
}

.form__input-field_columned {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form__button-box {
  display: flex;
  align-items: center;
}

.form__button-recovery {
  margin-left: 20px;
}

.social-auth-links {
  display: inline-flex;
}

.social-auth-links__icon {
  width: 25px;
  height: 25px;
  display: block;
}

.social-auth-links__link {
  display: block;
  text-decoration: none;

  & + & {
    margin-left: 15px;
  }
}
