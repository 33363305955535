.page__copyright {
  margin: 0;
  padding: 0;

  list-style-type: none;

  li {
    display: inline-block;
    margin-bottom: 5px;

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  a {
    display: block;

    transition: opacity 0.3s ease;
    text-decoration: none;

    color: var(--isp-text-main-color);

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    &:hover {
      opacity: 0.85;
    }
  }
}
