.page__preloader {
  font-weight: normal;
}

@import "__background-block/page__backgound-block";
@import "__container/page__container";
@import "__copyright/page__copyright";
@import "__error/page__error";
@import "__footer/page__footer";
@import "__content/page__content";
@import "__form/page__form";
@import "__input/form__input";
@import "__lang-switcher/page__lang-switcher";
@import "__logo/page__logo";
