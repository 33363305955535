.error-icon {
  width: 21px;
  margin-right: 15px;

  background: no-repeat url("../assets/info_icon_slim.svg");
  background-position: center;
}

.error-warning {
  position: absolute;
  top: -3px;
  right: 0;

  box-sizing: border-box;
  max-width: 230px;
  padding: 7px 30px 7px 10px;

  transform: translate(25px, -100%);

  border-radius: 3px;
  background-color: var(--isp-yellow-5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

  font-size: 14px;
}

.error-warning__pointer {
  position: absolute;
  top: 100%;
  right: 38px;

  overflow: hidden;

  width: 24px;
  height: 15px;

  &::after {
    content: "";
    position: absolute;
    right: 0;

    width: 12px;
    height: 12px;

    transform: translate(-50%, -50%) rotate(45deg) skew(8deg, 8deg) scale(1.3);

    background-color: var(--isp-yellow-5);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
}

.error-warning__close {
  position: absolute;
  top: 5px;
  right: 10px;

  width: 12px;
  height: 12px;

  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;

    width: 12px;
    height: 1px;

    background-color: #dadada;
  }

  &::before {
    transform: rotateZ(45deg);
  }

  &::after {
    transform: rotateZ(-45deg);
  }
}
