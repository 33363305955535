.page__lang-switcher {
  margin-right: 50px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.lang-switcher_current {
  position: relative;

  margin-left: 5px;
  padding-right: 15px;

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;

    right: 0;

    width: 7px;
    height: 1px;

    border-radius: 2px;
    background-color: var(--isp-text-main-color);
  }

  &::before {
    transform: translateX(-5px) rotateZ(45deg);
  }

  &::after {
    transform: rotateZ(-45deg);
  }
}

.language__list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.languange__item {
  margin-bottom: 1px;
  padding: 2px 5px;

  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--isp-yellow-5);
  }

  &.selected {
    font-weight: 700;
  }
}
